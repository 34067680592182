.fourofour
  text-align: center
  &__container
    padding-top: 1rem
    padding-bottom: 6rem
    &__cta
      margin-top: 3rem
  &__hero
    position: relative
    overflow: hidden
    background: rgba(0,0,0,.4)
    background-blend-mode: darken
    background-repeat: no-repeat
    background-size: cover
    height: 100vH
    @include media-breakpoint-down(md)
      height: 15rem
    margin-bottom: 3rem
    h1
      color: $white
      margin-top: 4rem
      @include media-breakpoint-down(md)
        font-size: ($font-size-base * 3)
      @include media-breakpoint-up(xl)
        margin-top: 6rem
      @media only screen and (min-width: 1281px)
        margin-top: 15rem
