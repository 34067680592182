//mixins 
@import styles/mixins/fontface

//fonts
@include fontFace('rockwell', '/assets/fonts/rockwell')

// theme
@import styles/prebootstrapvariables
@import ../node_modules/bootstrap/scss/functions
@import styles/bootstrapvariables
@import styles/variables

// vendor modules
@import ../node_modules/bootstrap/scss/bootstrap

// stylesheets containers
@import styles/containers/Root
@import styles/containers/App
@import styles/containers/Landingpage
@import styles/containers/Dashboard
@import styles/containers/FourOFour
@import styles/containers/UnlockableContents
//stylesheets components
@import ../node_modules/@bkry/bowline-components/Navbar/Navbar
@import ../node_modules/@bkry/bowline-components/BowlineWallet/BowlineWallet
@import ../node_modules/@bkry/bowline-components/ConnectWalletButton/AuthConnectButton
@import ../node_modules/@bkry/bowline-components/ConnectWallet/ConnectWallet

body
  margin: 0
  padding: 0
  overflow: scroll !important
  //font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace

.btn-circle-float
  position: fixed
  bottom: 1rem
  right: 1rem
  z-index: 999
  i
    transform: translateY(15%)

.cursor-pointer
  cursor: pointer

// No idea why we need this:
.card
  color: var(--bs-card-color)

// grayscale for usage on light and dark backgrounds
.btn-close
    filter: invert(1) grayscale(100%) brightness(50%)
