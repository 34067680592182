$bowline-gray: #373A3F
$bowline-green: #00c3a5
$bowline-dark-blue: #0C0E16

$letter-spacing-xs: 0.01em
$letter-spacing-s: 0.02em
$letter-spacing-m: 0.05em
$letter-spacing-l: 0.1em
$letter-spacing-xl: 0.13em
$letter-spacing-xxl: 0.14em

$letter-spacing-big: 0.2em
$letter-spacing-button: 0.23em
